<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <h2 class="font-semibold">Manage Product Category</h2>
        <button
          @click="navigateToAddProuductCategory"
          class="btn btn-sm bg-green-400 border-none"
        >
          Add New
        </button>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div
        v-if="!isLoading"
        class="
          overflow-x-auto
          bg-white
          rounded-lg
          shadow
          overflow-y-auto
          relative
          mt-5
        "
      >
        <table
          class="
            border-collapse
            table-auto
            w-full
            whitespace-no-wrap
            bg-white
            table-striped
            relative
          "
        >
          <thead>
            <tr class="text-left">
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Category Name
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs
                "
              >
                Last Updated At
              </th>
              <th
                class="
                  bg-gray-200
                  sticky
                  top-0
                  border-b border-gray-200
                  px-6
                  py-2
                  text-gray-600
                  font-bold
                  tracking-wider
                  uppercase
                  text-xs text-center
                "
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="item in categoryList" :key="item">
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{ item.productCategoryName }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <span
                  class="text-gray-700 px-6 py-3 flex items-center text-base"
                  >{{
                    moment(item.updated_at).format(
                      "ddd MMM DD, YYYY [at] hh:mm a"
                    )
                  }}</span
                >
              </td>
              <td class="border-dashed border-t border-gray-200 text-center">
                <button
                  @click="updateProductCategoryName(item.id)"
                  class="px-2"
                >
                  <PencilAltIcon class="h-8 w-8 text-yellow-500" />
                </button>
                <button
                  @click="deleteProductCategoryName(item.id)"
                  class="px-2"
                >
                  <TrashIcon class="h-8 w-8 text-red-500" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";

import { TrashIcon, PencilAltIcon } from "@heroicons/vue/outline";
import moment from "moment";

export default {
  name: "ManageProductCategory",
  components: { SideNavBar, TrashIcon, PencilAltIcon },
  data() {
    return {
      isLoading: true,
      categoryList: [],
    };
  },
  mounted() {
    this.moment = moment;
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      this.axios({
        url: "/productCategory/getProductCategory",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.categoryList = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    updateProductCategoryName(productCategoryID) {
      this.$swal({
        title: "Enter New Product Category Name",
        input: "text",
        confirmButtonColor: "#28a745",
        showCancelButton: true,
      })
        .then((result) => {
          if (!result.isDismissed) {
            this.axios({
              url: "/productCategory/updateProductCategory",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                productCategoryID: productCategoryID,
                productCategoryName: result.value,
              },
            }).then((response) => {
              this.showToastDialog(response);
              this.getCategoryList();
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    deleteProductCategoryName(productCategoryID) {
      this.$swal
        .fire({
          title: "Are you sure you want to delete this category?",
          confirmButtonColor: "#dc3545",
          confirmButtonText: "Yes",
          showCancelButton: true,
        })
        .then((result) => {
          if (!result.isDismissed) {
            this.axios({
              url: "/productCategory/deleteProductCategory",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              data: {
                productCategoryID: productCategoryID,
              },
            }).then((response) => {
              this.showToastDialog(response);
              this.getCategoryList();
            });
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    showToastDialog(response) {
      this.$swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        icon: response.status == 200 ? "success" : "error",
        title: response.data["message"],
      });
    },
    navigateToAddProuductCategory() {
      this.$router.push({ name: "AddProductCategory" });
    },
  },
};
</script>

<style scoped>
</style>